import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <nav className="fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-3 bg-mainColor">
      <div className="container px-2 lg:px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
            <a href="#home">
              {/* <img
            className="w-[150px]"
            src={require('../images/logo.png')}
            alt="rambara trans logo"
          /> */}
              <p className="uppercase font-bold">Rambara Trans</p>
            </a>
          </div>
          <button
            className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <FontAwesomeIcon
              icon={navbarOpen ? faClose : faBars}
              color="#eee"
              height={39}
            />
          </button>
        </div>
        <div
          className={
            'lg:flex flex-grow items-center' +
            (navbarOpen ? ' flex' : ' hidden')
          }
        >
          <ul className="flex flex-col py-4 lg:py-0 lg:flex-row list-none lg:ml-auto">
            <a href="#order" onClick={() => setNavbarOpen(!navbarOpen)}>
              <li className="nav-item cursor-pointer mt-3 lg:mt-0">
                <div className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">
                  <span className="ml-2">Order Unit</span>
                </div>
              </li>
            </a>
            <a href="#aboutus" onClick={() => setNavbarOpen(!navbarOpen)}>
              <li className="nav-item cursor-pointer mt-3 lg:mt-0">
                <div className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">
                  <span className="ml-2">Tentang Kami</span>
                </div>
              </li>
            </a>
            <a href="#galeri" onClick={() => setNavbarOpen(!navbarOpen)}>
              <li className="nav-item cursor-pointer mt-3 lg:mt-0">
                <div className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">
                  <span className="ml-2">Galeri</span>
                </div>
              </li>
            </a>
            <a href="#kontak" onClick={() => setNavbarOpen(!navbarOpen)}>
              <li className="nav-item cursor-pointer mt-3 lg:mt-0">
                <div className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">
                  <span className="ml-2">Kontak</span>
                </div>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
