import { Zoom } from 'react-awesome-reveal';
import background from '../images/bg.jpeg';

const Hero = () => {
  return (
    <div
      id="home"
      className="h-[100vh] w-full flex gap-4 flex-col justify-center items-center  px-4 md:px-2 text-center"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Zoom>
        <p className="text-base md:text-lg font-semibold">
          Travel Together and Happy With Us
        </p>
      </Zoom>
      <Zoom>
        <img
          className="w-[300px] md:w-[500px]"
          src={require('../images/logo.png')}
          alt=""
        />
      </Zoom>
      <Zoom>
        <p className="text-sm md:text-lg bg-white text-black p-6 uppercase rounded-tl-full rounded-br-full">
          Travel and tourism services
        </p>
      </Zoom>
    </div>
  );
};
export default Hero;
