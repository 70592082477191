import { Zoom } from "react-awesome-reveal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const data = [
  {
    image: "rambara-trans-1.jpeg",
    alt: "rambara trans 1",
  },
  {
    image: "rambara-trans-2.jpeg",
    alt: "rambara trans 2",
  },
  {
    image: "rambara-trans-3.jpeg",
    alt: "rambara trans 3",
  },
  {
    image: "rambara-trans-4.jpeg",
    alt: "rambara trans 4",
  },
  {
    image: "rambara-trans-5.jpeg",
    alt: "rambara trans 5",
  },
  {
    image: "rambara-trans-6.jpeg",
    alt: "rambara trans 6",
  },
  {
    image: "rambara-trans-7.jpeg",
    alt: "rambara trans 7",
  },
  {
    image: "rambara-trans-8.jpeg",
    alt: "rambara trans 8",
  },
];

const Galeri = () => {
  return (
    <div className="p-4 mt-8" id="galeri">
      <Zoom>
        <h1 className="text-secondary text-lg md:text-2xl font-bold text-center">
          Galeri
        </h1>
      </Zoom>
      <div className="max-w-md m-auto mt-4">
        <Carousel
          showStatus={false} // Hide status bar
          showThumbs={false} // Hide thumbnail images
        >
          {data.map((item) => (
            <div key={item.alt}>
              <img src={require(`../images/${item.image}`)} alt={item.alt} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
export default Galeri;
