import { Route, Routes } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Galeri from './components/Galeri';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Unit from './components/Unit';

function App() {
  return (
    <>
      <Navbar />
      <main>
        <Hero />
        <Unit />
        <AboutUs />
        <Galeri />
        <Contact />
        <Footer />
      </main>
    </>
  );
}

export default App;
