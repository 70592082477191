const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="text-center py-4 mt-10">
      <p className="text-xs font-extralight">
        Copyright © {currentYear} by Rambara Trans
      </p>
    </div>
  );
};
export default Footer;
