import { Zoom } from 'react-awesome-reveal';
import { ReactComponent as InstagramIcon } from '../images/instagram.svg';

const Contact = () => {
  return (
    <div className="p-4 mt-8" id="kontak">
      <div className="max-w-[300px] md:max-w-[800px] mx-auto">
        <Zoom>
          <h1 className="text-secondary text-lg md:text-2xl font-bold text-center">
            Hubungi Kami
          </h1>
        </Zoom>
        <Zoom>
          <h1 className="text-sm md:text-base font-bold mt-4">Alamat</h1>
          <p className="text-sm md:text-base mt-2">
            PW9H+RV9, RT.008/RW.008, Jatimakmur, Kec. Pd. Gede, Kota Bekasi,
            Jawa Barat
          </p>
        </Zoom>
        <Zoom>
          <h1 className="text-sm md:text-base font-bold mt-4">No HP/WA</h1>
          <p>+62 859-2239-6773</p>
        </Zoom>
        <Zoom>
          <h1 className="text-sm md:text-base font-bold mt-4">Social Media</h1>
          <div className="mt-2">
            <a
              href="https://www.instagram.com/rambaratrans"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon className="h-6 w-6" fill="white" />
            </a>
          </div>
        </Zoom>
      </div>
    </div>
  );
};
export default Contact;
