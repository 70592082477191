import { Zoom } from 'react-awesome-reveal';

const AboutUs = () => {
  return (
    <div className="p-4 mt-8" id="aboutus">
      <Zoom>
        <h1 className="text-secondary text-lg md:text-2xl font-bold text-center">
          Mengapa Memilih Kami?
        </h1>
      </Zoom>
      <Zoom>
        <img
          className="mt-8 w-[300px] md:w-[800px] mx-auto"
          src={require('../images/comfy.jpeg')}
          alt="rambara trans nyaman"
        />
      </Zoom>
      <ol className="mt-8 max-w-[300px] mx-auto md:max-w-[800px]">
        <Zoom>
          <li className="mt-4 text-sm md:text-base">
            <span className="font-bold">Kendaraan Terbaik:</span> Nikmati
            perjalanan dalam gaya dengan armada kendaraan terbaru kami yang
            nyaman dan modern.
          </li>
        </Zoom>
        <Zoom>
          <li className="mt-4 text-sm md:text-base">
            <span className="font-bold">Pengemudi Berpenglaman:</span> Anda akan
            ditemani oleh pengemudi profesional yang familiar dengan rute-rute
            terbaik, memastikan perjalanan Anda berjalan lancar.
          </li>
        </Zoom>
        <Zoom>
          <li className="mt-4 text-sm md:text-base">
            <span className="font-bold">Fleksibilitas Jadwal:</span> Kami
            mengerti setiap perjalanan adalah berbeda. Dengan kami, Anda dapat
            menyesuaikan jadwal perjalanan Anda sesuai keinginan.
          </li>
        </Zoom>
        <Zoom>
          <li className="mt-4 text-sm md:text-base">
            <span className="font-bold">Kenyamanan dan Keamanan:</span>{' '}
            Kendaraan kami selalu dalam kondisi prima, dan kami mengutamakan
            keselamatan Anda selama perjalanan.
          </li>
        </Zoom>
      </ol>
      <Zoom>
        <h1 className="text-secondary text-lg md:text-2xl font-bold text-center mt-8">
          Layanan Kami
        </h1>
        <Zoom>
          <img
            className="mt-8 w-[300px] md:w-[800px] mx-auto"
            src={require('../images/bg3.jpeg')}
            alt="rambara trans nyaman"
          />
        </Zoom>
        <ol className="mt-8 max-w-[300px] mx-auto md:max-w-[800px]">
          <Zoom>
            <li className="mt-4 text-sm md:text-base">
              <span className="font-bold">Sewa Harian dan Mingguan:</span> Pilih
              paket sewa harian atau mingguan yang sesuai dengan rencana
              perjalanan Anda.
            </li>
          </Zoom>
          <Zoom>
            <li className="mt-4 text-sm md:text-base">
              <span className="font-bold">Paket Wisata Khusus:</span> Nikmati
              perjalanan wisata yang dirancang khusus dengan layanan pemandu
              wisata yang berpengetahuan.
            </li>
          </Zoom>
          <Zoom>
            <li className="mt-4 text-sm md:text-base">
              <span className="font-bold">Perjalanan Keluarga dan Grup: </span>{' '}
              Kami menyediakan kendaraan yang luas dan nyaman untuk perjalanan
              keluarga besar atau rombongan.
            </li>
          </Zoom>
        </ol>
      </Zoom>
    </div>
  );
};
export default AboutUs;
