import { Zoom } from "react-awesome-reveal";
import ReactWhatsapp from "react-whatsapp";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const unitData = [
  {
    name: "Hiace Premio",
    images: [
      "rambaratrans-hiace-1.png",
      "rambaratrans-hiace-2.jpeg",
      "rambaratrans-hiace-3.jpeg",
    ],
    seat: "14 Seats",
  },
  {
    name: "Hiace Commuter",
    images: [
      "rambaratrans-hiace-commuter-1.png",
      "rambaratrans-hiace-commuter-2.jpeg",
      "rambaratrans-hiace-commuter-3.jpeg",
    ],
    seat: "15 Seats",
  },
  {
    name: "Hiace Premio Luxury",
    images: ["rambaratrans-hiace-1.png", "rambaratrans-hiace-luxury-2.jpeg"],
    seat: "8 Seats",
  },
  {
    name: "Innova Zenix Hybrid",
    images: [
      "rambaratrans-innova-zenix-1.png",
      "rambaratrans-innova-zenix-2.jpeg",
    ],
  },
  {
    name: "Avanza Veloz 2023",
    images: ["rambaratrans-avanza-veloz-1.png"],
  },
];

const Unit = () => {
  return (
    <div className="p-4 mt-8 text-center" id="order">
      <Zoom>
        <h1 className="text-secondary text-lg md:text-2xl font-bold">
          Order Unit
        </h1>
      </Zoom>
      <div className="flex flex-wrap gap-3 justify-center mt-5">
        {unitData.map((item) => (
          <div
            key={item.name}
            className="w-[300px] p-2 gap-3 flex justify-center items-center flex-col"
          >
            <Carousel
              showArrows={true}
              showStatus={false}
              showIndicators={false}
            >
              {item.images.map((image, index) => (
                <div key={index}>
                  <img
                    className="w-full"
                    src={require(`../images/${image}`)}
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
            <p className="uppercase font-semibold">{item.name}</p>
            <p className="text-sm">{item.seat}</p>
            <ReactWhatsapp
              number="+62 859-2239-6773"
              message={`Halo, saya ingin memesan ${item.name}`}
              className="bg-secondary rounded-sm p-2 font-bold text-primary w-full hover:opacity-80"
            >
              Order {item.name}
            </ReactWhatsapp>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Unit;
